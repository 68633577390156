import "bootstrap/dist/css/bootstrap.min.css"
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './src/css/layout.css'
import './src/css/style.css'
import './src/css/_utilities.scss'

import posthog from "posthog-js"

export const onClientEntry = () => {
  // Initialize PostHog
  if (typeof window !== 'undefined') {
    posthog.init('phc_PP6KNmgP7288dpUZTFh5kiH7szNvfI0sPLVX5pQaWgJ', {
      api_host: 'https://app.posthog.com',
      autocapture: true,
      capture_pageview: true,
      session_recording: {
        recordCanvas: true,
        recordCrossOriginIframes: true,
      },
      disable_session_recording: false,
    })
  }
}

export const onRouteUpdate = ({ location }) => {
  // Track page views on route changes
  if (typeof window !== 'undefined' && posthog) {
    posthog.capture('$pageview', {
      url: location.pathname,
    })
  }
}