exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accepted-js": () => import("./../../../src/pages/accepted.js" /* webpackChunkName: "component---src-pages-accepted-js" */),
  "component---src-pages-algoliatest-js": () => import("./../../../src/pages/algoliatest.js" /* webpackChunkName: "component---src-pages-algoliatest-js" */),
  "component---src-pages-auth-google-js": () => import("./../../../src/pages/auth/google.js" /* webpackChunkName: "component---src-pages-auth-google-js" */),
  "component---src-pages-crm-index-js": () => import("./../../../src/pages/crm/index.js" /* webpackChunkName: "component---src-pages-crm-index-js" */),
  "component---src-pages-crm-kanban-card-js": () => import("./../../../src/pages/crm/kanbanCard.js" /* webpackChunkName: "component---src-pages-crm-kanban-card-js" */),
  "component---src-pages-crm-kanban-column-js": () => import("./../../../src/pages/crm/kanbanColumn.js" /* webpackChunkName: "component---src-pages-crm-kanban-column-js" */),
  "component---src-pages-crm-kanban-js": () => import("./../../../src/pages/crm/kanban.js" /* webpackChunkName: "component---src-pages-crm-kanban-js" */),
  "component---src-pages-crm-layout-js": () => import("./../../../src/pages/crm/layout.js" /* webpackChunkName: "component---src-pages-crm-layout-js" */),
  "component---src-pages-crm-profile-js": () => import("./../../../src/pages/crm/profile.js" /* webpackChunkName: "component---src-pages-crm-profile-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jest-test-js": () => import("./../../../src/pages/jestTest.js" /* webpackChunkName: "component---src-pages-jest-test-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-pms-mentions-js": () => import("./../../../src/pages/pms/mentions.js" /* webpackChunkName: "component---src-pages-pms-mentions-js" */),
  "component---src-pages-pms-mystuff-js": () => import("./../../../src/pages/pms/mystuff.js" /* webpackChunkName: "component---src-pages-pms-mystuff-js" */),
  "component---src-pages-pms-projects-js": () => import("./../../../src/pages/pms/projects.js" /* webpackChunkName: "component---src-pages-pms-projects-js" */),
  "component---src-pages-pms-setting-js": () => import("./../../../src/pages/pms/setting.js" /* webpackChunkName: "component---src-pages-pms-setting-js" */),
  "component---src-pages-pms-tasks-js": () => import("./../../../src/pages/pms/tasks.js" /* webpackChunkName: "component---src-pages-pms-tasks-js" */),
  "component---src-pages-pms-templete-js": () => import("./../../../src/pages/pms/templete.js" /* webpackChunkName: "component---src-pages-pms-templete-js" */),
  "component---src-pages-received-js": () => import("./../../../src/pages/received.js" /* webpackChunkName: "component---src-pages-received-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-layout-js": () => import("./../../../src/pages/register/layout.js" /* webpackChunkName: "component---src-pages-register-layout-js" */),
  "component---src-pages-rejected-js": () => import("./../../../src/pages/rejected.js" /* webpackChunkName: "component---src-pages-rejected-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sent-js": () => import("./../../../src/pages/sent.js" /* webpackChunkName: "component---src-pages-sent-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

